.Sponsor_wrap,
.Making_wrap,
.Reservation_wrap {
    display: flex;
    flex-direction: column;
    padding: 60px 24px 20px 24px;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 30px;

        h4 {
            font-weight: 600;
        }

        .back {
            left: 0;
            position: absolute;
            width: 24px;
            height: 32px;
            cursor: pointer;
        }

        .bar {
            width: 295px;
            height: 8px;
            background-color: #ffffff30;
            border-radius: 50px
        }
    }
}

.Sponsor_wrap {
    .main {
        position: relative;

        h3 {
            color: var(--gray700);
            font-weight: 700;
            margin-bottom: 24px;
        }

        .list_wrap {
            height: 650px;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 8px;
            
            
            .list {
                display: flex;
                flex-direction: column;
                border: 1px solid var(--gray200);
                border-radius: 4.31px;
                padding: 0 16px;
                height: 134px;
                position: relative;

                .top {

                    .desc {
                        font-size: 12px;
                        margin-top: 16px;
                    }

                    .title {
                        font-size: 14px;
                        color: var(--white);
                    }
                }

                .bottom {
                    display: flex;
                    width: 100%;
                    justify-content: end;

                    img {
                        position: absolute;
                        right: 16px;
                        bottom: 26px;

                    }
                }
    
                &:nth-child(1) {
                    background-color: #136FE5;

                    .desc {
                        color: #AFD0FB;
                    }
                    
                    img {
                        height: 41.88px;
                        width: 250px;
                    }
                }

                &:nth-child(2) {
                    background-color: #49EB3E;

                    .desc {
                        color: #F3FCF2;
                    }

                    img {
                        height: 69.96px;
                        width: 209px;
                        bottom: 17px;
                    }
                }

                &:nth-child(3) {
                    background-color: #2A2822;

                    .desc {
                        color: #CACACA;
                    }
                    
                    img {
                        height: 38px;
                        width: 241.97px;
                    }
                }
            }
        }
    }

    .list_wrap::-webkit-scrollbar {
        display: none;
    }
}

.Making_wrap {
    .main {
        position: relative;
        height: 750px;
        overflow-y: scroll;

        >div {
            margin-bottom: 30px;

            h3 {
                color: var(--gray700);
                font-weight: 700;
                margin-bottom: 24px;
            }

            .list_wrap {
                h5 {
                    font-weight: 700;
                    color: var(--maincolor);
                    margin-bottom: 15px;
                }

                .up {
                    margin-top: 20px;
                }

                .lion_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .lion {
                        width: 48%;
                        height: 270px;
                        background-color: #E1D5FA90;
                        border: 1px solid var(--purple03);
                        border-radius: 10px;
                        position: relative;
                        background-image: url(../../img//etc/logo.png);
                        background-position: right bottom;
                        background-repeat: no-repeat;
                        background-size: 95%;
                        overflow: hidden;
                        margin-bottom: 15px;
                        cursor: pointer;

                        .text {
                            padding: 20px 15px;

                            .organization {
                                font-size: 12px;
                            }

                            .name {
                                font-weight: 500;
                                color: var(--maincolor);
                                margin-bottom: 2px;
                            }

                            .department {
                                font-size: 10px;
                                color: var(--gray600);
                                margin-bottom: 2px;
                            }

                            .desc {
                                font-size: 12px;
                                color: var(--gray600);
                                margin-bottom: 2px;
                            }
                        }

                        img {
                            position: absolute;
                            width: 145px;
                            right: -10px;
                            bottom: 0;
                            max-height: 168px;
                            object-fit: contain;
                            mix-blend-mode: luminosity;


                        }

                        &:nth-child(6) {

                            img {
                                right: -17px;
                            }
                        }

                        .role {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            border-radius: 10px;
                            background-image: url('../../img/etc/back.avif');
                            display: block;
                            opacity: 40%;
                            transform: translateY(-100%);
                            transition: transform 0.5s ease-out, opacity 0.5s ease-out;
                            font-size: 12px;

                            >p:nth-child(1) {
                                padding: 20px 16px 7px 16px;
                            }

                            >p:nth-child(2) {
                                color: var(--maincolor);
                                padding: 0 16px;
                            }

                        }
                    }

                    .lion.active {

                        div {
                            filter: blur(5px);
                        }

                        img {
                            filter: blur(5px);
                        }

                        .role {
                            display: block;
                            filter: blur(0);
                            transform: translateY(0);
                        }

                    }
                }

                .festival_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .festiver {
                        width: 48%;
                        height: 95px;
                        padding: 20px 15px;
                        background-color: #E1D5FA90;
                        border-radius: 10px;
                        border: 1px solid var(--purple03);
                        margin-bottom: 15px;

                        .organization {
                            font-size: 12px;
                        }

                        .name {
                            font-weight: 500;
                            margin-bottom: 2px;
                            color: var(--maincolor);
                        }

                        .department {
                            font-size: 10px;
                            margin-bottom: 2px;
                            color: var(--gray600);
                        }
                    }
                }
            }
        }
    }

    .main::-webkit-scrollbar {
        display: none;
    }
}

.Reservation_wrap {
    display: flex;
    flex-direction: column;
    padding: 60px 24px 20px 24px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    margin: 0 auto;

    .swiper-container {
        height: auto;
    }

    .main {
        position: relative;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        height: 650px;
    }

    >a {
        bottom: 20px;
    }
}

/* 활성화된 Swiper pagination bullet 색상 */
.swiper-pagination-bullet-active {
    background-color: #5C4795 !important;
}

.Reservation_wrap.slide-in {
    opacity: 1;
    transform: translateX(0);
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
}

.header h4 {
    font-weight: 600;
}

.header .back {
    left: 0;
    position: absolute;
    width: 24px;
    height: 32px;
    cursor: pointer;
}

.swiper-container {
    width: 100%;
    height: auto;
}

.full-width-image {
    width: 100;
    height: auto;
    object-fit: cover;
}

h3 {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 10px;
    color: #5C4795;
    z-index: 11;
}

.reservation-info-box {
    background-color: #5c47950d;
    /* Transparent background */
    padding: 20px;
    margin-top: 5px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    z-index: 1;
}

.reservation-info-box p {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgb(0, 0, 0);
}

.floating-button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5C4795;
    border-radius: 10px;
    color: white;
    text-decoration: none;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 420px) {
    .floating-button {
        bottom: 5px;
        font-size: 14px;
    }
}

.banner_wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .swipe {
        border-radius: 6px;
        position: relative;
        height: 75px;
        display: flex;
        width: 100%;
        min-width: 345px;
        max-width: 350px;

        img {
            object-position: center;
            object-fit: fill;
            height: 75px;
            border-radius: 6px;
        }


    }
}