.Booth_wrap {
    padding-top: 60px;
    display: flex;
    flex-direction: column;

    .wrap {

        width: 345px;
        margin: 0 auto;
    }

    header {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .back_btn {
           
            position: absolute;
            left: 24px;
        }
        .back_btn img{
            width: 24px;
            height: 32px;
        }

        .back_btn:hover {
            cursor: pointer;
        }

        .title {
            font-size: 16px;
            font-weight: bold;
            color: var(--black);
        }
        .filter-icon {
            width: 24px;
            height: 32px;
            cursor: pointer;
            position: absolute;
            right: 24px;
        }
    }


    .Booth-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: var(--black);
        padding-left: 130px;
        position: relative;
    }

   

    .back-icon,
    .Booth-title,
    .filter-icon {
        display: inline-block;
        vertical-align: middle;
    }

    .banner {
        margin-top: 20px;

        position: relative;


    }
}

.booth-search {
    margin-top: 30px;
    position: relative;
    margin: 0 auto;

    .booth-search-title {
        display: flex;
        margin-bottom: 20px;
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;

        >h2 {
            font-size: 18px;
            font-weight: bold;
            color: var(--black);
        }

        .Gofood {
            width: 90px;
            height: 19px;
            border-radius: 4px;
            font-size: 8px;
            font-weight: 700;
            background-color: #5c479537;
            display: flex;
            justify-content: center;
            align-items: center;
        }


    }

    .booth-search-bar {
        position: relative;
        display: flex;
        align-items: center;


        .booth-search-bg {
            position: absolute;
            left: 0;
            width: 345px;
            height: 52px;
            z-index: 0;

        }

        .search-input {
            position: relative;
            width: 100%;
            padding: 10px 20px;
            font-size: 16px;
            border: none;
            z-index: 1;
            background: transparent;


            &::placeholder {
                color: #999;
            }
        }

        .booth-search-icon {
            width: 18px;
            height: 18px;
            margin-right: 16px;
        }
    }
}

.booth-list-container {
    position: relative;

    margin-top: 25px;
    min-height: 550px;
    max-height: 550px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .booth-list {

        .booth-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            border-radius: 10px;



            .booth-image {
                width: 134px;
                height: 134px;
                border-radius: 8px;

                margin-top: 5px;
                object-fit: cover;
            }

            .booth-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;
                padding: 15px 23px;
                margin-left: 5px;
                border-radius: 10px;
                position: relative;
                z-index: 1;
                height: 134px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 202px;
                    height: 134px;
                    margin-top: 3px;
                    margin-left: 5px;
                    background-color: rgba(255, 255, 255, 0.05);
                    border-radius: 10px;
                    border: 2px solid var(--purple03);
                    z-index: -1;
                }

                .booth-name-location {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .booth-name {
                        font-size: 15px;
                        font-weight: bold;
                        color: var(--maincolor);
                        flex-grow: 1;
                        width: 115px;  
                        padding: 0 3px;     
                        white-space: pre-wrap;           

                    }

                    .booth-location {
                        font-size: 12px;
                        color: var(--purple01);
                        font-weight: bold;

                    }
                }

                .booth-details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .booth-date {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 10px;
                        color: var(--gray500);
                    }

                    .booth-button {
                        width: 56px;
                        height: 16px;
                        background-color: rgba(255, 255, 255, 0.2);
                        border: none;
                        color: var(--purple01);
                        border-radius: 4px;
                        cursor: pointer;
                        font-size: 8px;
                        font-weight: bold;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }


                }
            }
        }
    }
}

.filter-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .filter-content {
        background-image: url('../../img/booth/filterbg.png');
        background-size: cover;
        padding: 20px;
        border-radius: 10px;
        width: 345px;
        max-width: 360px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        color: var(--gray800);

        .filter-title {
            font-size: 14px;
            margin-bottom: 10px;
            color: var(--gray600);
        }

        .filter-options {
            display: flex;
            gap: 7px;
            margin-bottom: 20px;
            flex-wrap: wrap;

            button {
                background: none;
                border: 1px solid var(--gray500);
                border-radius: 15px;
                padding: 6px 9px;
                font-size: 12.5px;
                cursor: pointer;
                color: var(--gray500);

                &.active {
                    background-color: rgba(163, 132, 188, 0.3);
                    border: 1px solid var(--purple01);
                    color: var(--maincolor);
                }
            }
        }

        .filter-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .cancel-button,
            .apply-button {
                width: 48%;
                padding: 13px 8px;
                font-size: 16px;
                border-radius: 10px;
                cursor: pointer;
                margin-top: 8px;
                margin-bottom: 8px;
            }

            .cancel-button {
                border: 1px solid var(--maincolor);
                color: var(--maincolor);
            }

            .apply-button {
                background-color: var(--maincolor);
                color: var(--white);
                border: none;
            }
        }
    }
}

.BoothDetail {
    position: relative;
    overflow-x: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;

    header {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .back_btn {
            width: 24px;
            height: 32px;
            position: absolute;
            left: 24px;
        }

        .back_btn:hover {
            cursor: pointer;
        }

        .title {
            font-size: 16px;
            font-weight: bold;
            color: var(--black);
        }
    }

    .info {
        position: relative;
        height: calc(100vh - 120px);
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        .img {
            width: 100%;
            background-color: #fff;
        }

        .wrap {
            width: 90%;
            margin: 20px auto;
            gap: 20px;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 18px;
                color: var(--maincolor);
                font-weight: bold;
            }

            .date_loca {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dates {
                    display: flex;
                    gap: 10px;

                    .date {
                        width: 90px;
                        height: 30px;
                        border-radius: 24px;
                        font-size: 12px;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .date:first-child {
                    background-color: #a384bc37;
                    border: solid 1.2px var(--purple01);

                }

                .date:last-child {
                    background-color: #91A7D337;
                    border: solid 1.2px var(--blue02);
                }

                .location {
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--purple01);
                }
            }

            .subtitle {
                height: 17px;
                font-size: 12px;
                font-weight: 500;
                color: var(--purple01);
            }

            .boothInfo,
            .boothItem {
                width: 346px;
                padding: 24px 16px;
                background-color: #5C479505;
                border-radius: 8px;
                margin-top: -10px;
                gap: 10px;
                display: flex;
                flex-direction: column;

                >p {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--black);
                    font-size: 14px;
                    font-weight: 600;

                }
            }
        }
    }
}