/* 총학부터 힐링 포토존 묶음 */
.shifted-program {
    margin-left: 105px;
    margin-top: -30px;
}

.Timetable_wrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/timetable/background.png);
    position: relative;
    overflow-x: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 25px;

        .back {
            position: absolute;
            left: 0;
            width: 24px;
            height: 32px;
            bottom: 5px;
        }

        h4 {
            text-align: center;
            font-weight: 600;
        }
    }

    .tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        position: relative;
    }
    
    .tabs::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5.5px; 
        width: 100%;
        border-bottom: 1.5px solid #b2b2b4; 
        z-index: 0; 
    }

    button {
        pointer-events: auto;
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #7B7B7E;
        padding: 10px 0px;
        margin: 0 25px;
        border-bottom: 2px solid transparent;
        transition: border-color 0.3s ease;
        position: relative;
        z-index: 1; 

        &.active {
            position: relative;
            border: none;
            color: #242427;
        }
    
        &.active::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: var(--maincolor); 
            border-radius: 3px;
        }
    }

    .now-on {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 10px;
        width: 90%;
        max-width: 600px;
        margin: 0 auto 20px auto;
        background-color: rgba(0, 0, 0, 0);
        padding-top: 60px;

        border-radius: 12px;
        justify-content: center;

        .now-on-image {
            position: absolute;
            top: 0;
            left: -13px;
            width: 107%;
            object-fit: cover;
            border-radius: 12px;
            z-index: 1;
            justify-content: center;
        }

        .now-on-details {
            width: 100%;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            color: #fff;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 5px;
        }

        .now-on-time,
        .now-on-name,
        .now-on-location {   
            width: 200px;                                   
            text-align: center;
            flex: 1;
            text-align: center;
            width: 70px;
            margin-top: -5px;
        }

        .now-on-time {
            font-size: 13px;
            margin-bottom: 5px;
            font-weight: normal;
            color: #7B7B7E;
        }

        .now-on-name {
            font-size: 15px;
            margin-bottom: 5px;
            font-weight: 600;
            color: #5C4795;
        }

        .now-on-location {
            font-size: 13px;
            font-weight: normal;
            color: #7B7B7E;
            margin-bottom: 5px;
        }

        .now-on-none {
            font-size: 14px;
            font-weight: normal;
            color: #7B7B7E;
            margin-left: 15px;
        }
    }

    .timetable {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        width: 90%;
        margin: 35px auto;
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        backdrop-filter: blur(10px);
        height: 74%;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;

        position: relative;
        background-image: url('../../img/timetable/logo_main.avif');
        background-size: 50% 80%;
        background-position: right top;
        background-repeat: no-repeat;
    }

    .vertical-line {
        position: absolute;
        top: 50px;
        height: 550px;
        left: 117px;
        width: 0; 
        border-left: 1px dashed #C7C2DE; 
    }

    .timetable::-webkit-scrollbar {
        display: none;
    }

    .program-item {
        display: flex;
        width: 100%;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 8px;
        color: #fff;
    }

    .program-item:last-child {
        margin-bottom: 0;
    }

    .program-time {
        display: flex;
        flex-direction: column;
        width: 70px; 
        text-align: left;
        font-size: 14px;
        color: #272727;
        font-weight: 500;

        .fin-time{
            font-size: 12px;
            font-weight: 300;
            margin-top: 5px;
        }
    }
    
    .program-item .program-time p {
        margin: 0; 
        margin-top: 3px;
    }

    .program-details {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-start;
    }
    
    .program-details img {
        margin-right: 10px;
    }

    .event-info {
        display: flex;
        flex-direction: column;
    }

    .program-name {
        font-size: 16px;
        font-weight: bold;
        color: #5C4795;
    }

    .program-location {
        font-size: 14px;
        color: #272727;
    }

    .ongoing-image {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        margin-top: -20px;
    }

    .program-time,
    .program-details {
        position: relative;
        z-index: 1;
    }
}