.Login_wrap {
    padding: 60px 25px 20px 25px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    color: var(--black);

    .header{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 35px;
        width: 100%;

        .back {
            width: 24px;
            position: absolute;
            left: 0px;
            transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
        
            img {
                width: 10px;
            }
        }
        
        p {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
        }
    }

    .main{
        margin-top: 16px;
        position: relative;

        .email{
            margin-top: 32px;
            width: 345px;
            height: 101px;
            padding: 12px;

            p{
                font-size: 14px;
                color: var(--gray600);
            }

            >div {
                display: flex;

                .id {
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid var(--gray300);
                    width: 147px;
                    padding: 8px 0;
                    outline: none;
                    font-size: 16px;
                    cursor: pointer;

                    transition: border-bottom 400ms ease-in;
                }

                .id:focus {
                    border-bottom: 1px solid var(--maincolor);
                }

                p {
                    font-size: 16px;
                    text-align: center;
                    color: var(--gray600);
                }

            }

            
        }

        .password {
            width: 345px;
            height: 86px;
            padding: 12px;

            p{
                font-size: 14px;
                color: var(--gray600);
            }

            >div {
                display: flex;
        
                .pw{
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid var(--gray300);
                    width: 321px;
                    padding: 8px 0;
                    outline: none;
                    font-size: 16px;
                    cursor: pointer;

                    transition: border-bottom 400ms ease-in;
                }

                .pw:focus {
                    border-bottom: 1px solid var(--maincolor);
                }

                img {
                    position: absolute;
                    width: 16px;
                    height: 10.91;
                    right: 20px;
                    top: 285px;
                }
            }
        }
        
        .submit-button {
            width: 345px;
            height: 48px;
            background-color: var(--gray400);
            color: var(--white);
            font-weight: bold;
            margin-top: 24px;
            border-radius: 10px;
            transition: all 400ms ease-in;

            &.active {
                background-color: var(--maincolor);
                cursor: pointer;
            }
    
            &.disable {
                background-color: var(--gray400);
                cursor: not-allowed;
            }
        }

        .option {
            margin-top: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--maincolor);
            font-size: 14px;

            .join{
                font-weight: bold;
                text-decoration: underline;
                cursor: pointer;
                padding: 5px;

                transition: transform 400ms ease-in-out, opacity 400ms ease-in-out
            }
        }


    }

    .popup_wrap {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #00000060;
        width: inherit;
        height: inherit;

        .pop {
            width: 275px;
            height: 265px;
            background-color: var(--white);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >img {
                width: 86px;
                margin-bottom: 26px;
            }

            h3 {
                margin-bottom: 20px;
                font-weight: 700;
            }

            button {
                width: 243px;
                height: 48px;
                background-color: var(--gray100);
                border-radius: 10px;
            }
        }
    }
}

