.Join_wrap {
    padding: 60px 25px 20px 25px;
    overflow: hidden;
    color: var(--black);
    position: relative;

    >div {
        position: relative;
        height: calc(100vh - 80px);

        .header {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 35px;

            .back {
                position: absolute;
                left: 0;
                width: 24px;
                height: 32px;
            }

            h4 {
                text-align: center;
                font-weight: 600;
            }
        }
    }

    .Terms_wrap {
        .main {
            h2 {
                font-weight: 800;
                margin-bottom: 20px;
            }

            >h4 {
                padding: 0 10px;
                font-weight: 600;
                margin-bottom: 16px;
            }

            .text {
                overflow-y: auto;
                scrollbar-width: none;
                -ms-overflow-style: none;
                background-color: #5c479510;
                font-size: 12px;
                padding: 24px 16px;
                border-radius: 8px;
                line-height: 1.3;
                height: 700px;

                >div {
                    align-items: flex-start;
                    font-weight: 400;

                    .bull {
                        margin-right: 5px;
                    }
                }
            }

            div {
                display: flex;
                align-items: center;
                font-weight: 500;

                .check {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

            .all {
                height: 52px;
                background-color: #5C479510;
                border-radius: 10px;
                padding: 20px;
                margin-bottom: 10px;
                font-weight: 700;
            }

            .box {
                padding: 10px 20px;
                justify-content: space-between;
                font-size: 14px;
            }
        }

        >button {
            position: absolute;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
            font-weight: 600;
            font-size: 16px;
            background-color: var(--gray400);
            border-radius: 10px;
            bottom: 70px;
            transition: all 0.4s;
        }

    }

    .JoinForm_wrap {
        .main {
            h2 {
                color: var(--black);
                font-weight: 800;
                margin-bottom: 10px;
                height: 66px;
            }

            >div {
                padding: 12px 11px;

                >p {
                    font-size: 14px;
                    color: var(--gray600);
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                input {
                    font-size: 16px;
                    padding: 10px 0;
                    width: 100%;
                    background-color: var(--none);
                    border-bottom: 1px solid var(--gray300);
                    transition: all 0.4s;
                }
            }

            .password {
                >div {
                    transition: all 0.4s;
                    border-bottom: 1px solid var(--gray300);
                }
                input {
                    border: 0;
                }
            }

            .email_check {
                >div {
                    div {
                        width: calc(100% - 68px);
                        border-bottom: 1px solid var(--gray300);
                        transition: all 0.4s;

                        p {
                            color: var(--maincolor);
                            font-size: 14px;
                        }
                    }

                    button {
                        border-radius: 10px;
                        font-size: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 55px;
                        height: 36px;
                        color: var(--maincolor);
                        border: 1px solid var(--maincolor);
                    }
                }

                input {
                    border: 0;
                }
            }

            .email {
                >div {
                    justify-content: left;

                    p {
                        color: var(--gray600);
                    }
                }

                input {
                    width: 147px;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type="number"] {
                    -moz-appearance: textfield;
                }
            }

            .border {
                border-bottom: 1px solid var(--maincolor) !important;
            }

            .red {
                border-bottom: 1px solid #FF4A4A !important;
            }

            .invalid {
                margin-bottom: 5px;
                border-bottom: 2px solid #FF4A4A !important;
            }
        }

        >button {
            position: absolute;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
            font-weight: 600;
            font-size: 16px;
            background-color: var(--gray400);
            border-radius: 10px;
            bottom: 70px;
            transition: all 0.4s;
        }

        .full {
            background-color: var(--maincolor);
        }
    }

    .popup_wrap {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #00000060;
        width: inherit;
        height: inherit;

        .pop {
            width: 275px;
            height: 265px;
            background-color: var(--white);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >img {
                width: 86px;
                margin-bottom: 26px;
            }

            h3 {
                margin-bottom: 20px;
                font-weight: 700;
            }

            button {
                width: 243px;
                height: 48px;
                background-color: var(--gray100);
                border-radius: 10px;
            }
        }
    }

    
}

.full {
    background-color: var(--maincolor) !important;
}