
.Program_wrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/timetable/background.png);
    position: relative;
    overflow-x: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
    max-width: 420px;
    margin: 0 auto;
    height: 100vh; 

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 25px;

    .back {
      position: absolute;
      left: 30px;
      width: 24px;
      height: 32px;
    }

    h4 {
      text-align: center;
      font-weight: 600;
    }
  }

  .swiper-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 120px); /* Adjust height based on remaining space after header and padding */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 20px;
    position: relative;
  }

  .pamphlet-page {
    width: 94%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .page-number {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    z-index: 10;
    background-color: rgba(40, 40, 40, 0.7);
    border-radius: 50px;
    padding: 5px 15px;
    transition: opacity 0.5s ease-in-out;
  }

  .swiper-pagination-bullet {
    background-color: #5C4795;
    width: 12px;
    height: 12px;
    opacity: 0.7;
    margin: 0 6px;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #5C4795;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
    font-weight: bold;
  }
}

/* Responsive design adjustments for smaller screens */
@media screen and (max-width: 420px) {
  .Program_wrap {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .swiper-container {
    height: calc(100vh - 80px); /* Adjust based on reduced padding */
  }

  .header h4 {
    font-size: 18px;
  }

  .page-number {
    font-size: 14px;
    bottom: 40px;
  }
}
