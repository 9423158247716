// 전역으로 사용하는 공통 css 입니다! 추가 시엔 꼭꼭 주석 처리 + commit 에 설명 + 톡방에 말씀해주세요~!

// 가시성을 위해 기본적으로 버튼은 cursor:pointer로 설정했습니다.
button, a {
    cursor: pointer;
    background-color: var(--none);
}

body {
    background-color: var(--black);
}

// 각 컴포넌트 가장 바깥 div에 해당 클래스를 넣으면 자동으로 크기가 설정됩니다.
.container {
    width: 100%;
    margin: 0 auto;
    background-color: var(--body);
    min-width: 393px;
    max-width: 420px;
    height: 100vh;
    min-height: 852px;
    position: relative;
}

.container::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    min-width: 393px;
    max-width: 420px;
    height: 100vh;
    min-height: 852px;
    background-position: center top;
    background-size: cover;
    background-image: url(../../img/main/Background.png);
    background-repeat: no-repeat;
}

// 해당 클래스를 붙이면 display가 무조건 none이 됩니다.
.none {
    display: none !important;
}

input {
    outline: none;
}