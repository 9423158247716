.FoodInfo_wrap {
    position: relative;
    overflow-x: hidden;

    padding-top: 60px;
    padding-bottom: 60px;
    z-index: 1;

    .table {
        width: 345px;
        margin: 16px auto;
        position: relative;
        z-index: 10;

        .food_name {
            font-weight: bold;
            margin-bottom: 8px;
            color: var(--black);
        }

        .ingredients {
            width: 345px;
            padding: 24px 16px;
            border-radius: 8px;
            background-color: #5c47950e;
            display: flex;
            align-items: center;

            p {
                color: var(--black);
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    header {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .back_btn {
            width: 24px;
            height: 32px;
            position: absolute;
            left: 24px;
        }

        .back_btn:hover {
            cursor: pointer;
        }

        .title {
            font-size: 16px;
            font-weight: bold;
            color: var(--black);
        }
    }

    main {
        display: block;
        position: relative;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        height: 720px;
    }

    .category {
        padding: 8px 22px;
        color: var(--gray500);
        display: flex;

        >div {
            border: 1.5px solid var(--gray500);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            font-size: 12px;
            font-weight: 600;
        }

        >div:hover {
            cursor: pointer;
        }

        .cate_food,
        .cate_dessert {
            width: 55px;
            height: 30px;
            border-radius: 24px;

        }

        .cate_drink {
            width: 45px;
            height: 30px;
            border-radius: 24px;
        }

        .onClick {
            background-color: #a384bc3a;
            border: 1.5px solid var(--purple01);
            color: var(--maincolor);
        }
    }

    .hide {
        display: none;
    }



}