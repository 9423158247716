.Manger_wrap {
    padding: 60px 25px 20px 25px;
    color: var(--black);

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 30px;

        .back {
            position: absolute;
            left: 0;
            width: 24px;
            height: 32px;
        }

        h4 {
            text-align: center;
            font-weight: 600;
        }
    }

    .main {
        position: relative;

        >div {
            padding: 12px 11px;
            margin-bottom: 25px;

            >p {
                font-size: 14px;
                color: var(--gray600);
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            input {
                font-size: 16px;
                padding: 10px 0;
                width: 100%;
                background-color: var(--none);
                border-bottom: 1px solid var(--gray300);
                transition: all 0.4s;
            }
        }

        .password_box {
            margin-bottom: 40px;

            >div {
                transition: all 0.4s;
                border-bottom: 1px solid var(--gray300);
            }

            input {
                border: 0;
            }
        }

        .border {
            border-bottom: 1px solid var(--maincolor) !important;
        }

        >button {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
            font-weight: 600;
            font-size: 16px;
            background-color: var(--gray400);
            border-radius: 10px;
            transition: all 0.4s;
        }

        .full {
            background-color: var(--maincolor);
        }
    }
}