.Main_wrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/main/landing_backgrondimage.png);
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .fade_out_wrapper {
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        .hourclass_wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .hourclass {
                width: 317px;
                height: 443px;
            }

            .hourclassglow {
                position: absolute;
                top: -60px;
                left: 49%;
                transform: translateX(-50%);
            }

            .light01 {
                width: 373px;
                position: absolute;
                top: -10px;
                left: 48%;
                transform: translateX(-50%);
            }

            .Logo {
                width: 345px;
                position: absolute;
            }
        }

        .text_wrap {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            left: 50%;
            transform: translateX(-50%);

            .text01 {
                margin-top: 8px;
                width: 263px;
            }

            .text02 {
                width: 366px;
                margin-bottom: 15px;
            }

            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                >p {
                    text-align: center;
                    font-size: 12px;
                    color: #D6C7FF;
                }
            }
        }
    }

    .Nav_wrap {
        height: 892px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        position: relative;
        padding-bottom: 40px;

        .yes_login {
            top: 50px;
            right: 35px;
            position: absolute;
            display: flex;
            align-items: center;
            z-index: 20;

            >a {
                width: 75px;
                height: 32px;
                margin-right: 10px;
                background-color: var(--maincolor);
                border-radius: 43px;
                color: var(--white);
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 100 !important;
                font-size: 14px;
            }

            .logout {
                cursor: pointer;
                color: var(--maincolor);
                font-size: 14px;
            }
        }

        .login {
            position: absolute;
            top: 50px;
            right: 35px;
            color: var(--maincolor);
            z-index: 2;
            font-size: 14px;
        }

        .img_box {
            width: 100%;
            padding-top: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .hourclass {
                width: 317px;
                height: 443px;
                opacity: 0.4;
            }

            .Logo {
                width: 345px;
                position: absolute;
            }

            .hourclassglow {
                opacity: 0.4;
                position: absolute;
                top: 58px;
                left: 49%;
                transform: translateX(-50%);
            }

            .light01 {
                opacity: 0.4;
                width: 373px;
                position: absolute;
                top: 110px;
                left: 48%;
                transform: translateX(-50%);
            }
        }

        .nav_box {
            padding: 0px 20px 8px 20px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            color: var(--maincolor);
            font-size: 14px;
            justify-content: space-between;

            .top, .bottom {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                >a {
                    cursor: pointer;
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    background-image: url(../../img/main/light04.svg);
                    font-weight: 600;
                    height: 75px;
                    margin-bottom: 5px;
                    background-color: #ffffff40;
                    border-radius: 12px;
                    padding: 12px;
                    border: 1px solid #5C479550;
                }
            }

            .top {
                >a {
                    width: 32.5%;
                }
            }

            .bottom {
                >a {
                    padding: 12px 4px 12px 10px;
                    width: 24%;
                }
            }
        }
    }
}


.Main_wrap::-webkit-scrollbar {
    width: 5px;
}

.Main_wrap::-webkit-scrollbar-thumb {
    background: var(--maincolor);
    border-radius: 10px;
}

.Main_wrap::-webkit-scrollbar-track {
    background: var(--black400);
}

.Footer_wrap {
    background-color: var(--gray900);
    padding: 30px;
    height: 160px;
    color: var(--purple03);
    position: relative;

    .top {
        margin-bottom: 20px;

        h5 {
            font-size: 12px;
            margin-bottom: 5px;
        }

        p {
            font-size: 10px;
        }
    }

    .bottom {
        display: flex;
        font-size: 10px;
        justify-content: space-between;

        a {
            width: 33.3%;
            text-align: center;
        }

        .left {
            border-right: 1px solid var(--purple03);
        }

        .right {
            border-left: 1px solid var(--purple03);
        }
    }
}

